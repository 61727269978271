/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';

import {
  LINK_TYPE,
  HEADER_LINK_LIST,
  SOURCE_TYPE,
} from 'src/components/common/headerv1/headerv1';
import { SOCIAL_LINKS } from 'src/common/components/Footer/footer';
import { getOriginByEnv } from 'core-lib/config/config';
import { redirectToPWA } from 'src/utils/isAuthAndRedirect';
import ParkplusSVG from 'src/common/icons/parkplus-website-logo.svg';
import s from './page_header.module.scss';
import BackIconSVG from '../../../common/icons/back.svg';
import { getUserProfilePic, isObject } from 'core-lib/utils/helpers';
import { loginPopupUpdate } from 'auth/store/action';

import MenuSVG from '../../../common/icons/nav-menu.svg';
import CrossSVG from '../../../common/icons/cross.svg';
import ChevronRight from 'ui/components/newIcons/ChevronRight';
import { BackButton } from 'ui';
function defaultLogoClick() {
  global.window.location.href = `${getOriginByEnv()}/`;
}

type PageHeaderV1Props = {
  showBackBtn?: boolean;
  onBackBtnClick?: () => void;
  showLogo?: boolean;
  onLogoClick?: () => void;
  showProfile?: boolean;
  customStyle?: any;
  showMenu?: boolean;
};

export function PageHeaderV1({
  showBackBtn = false,
  onBackBtnClick = null,
  showProfile = false,
  customStyle = null,
  showLogo = true,
  onLogoClick = defaultLogoClick,
  showMenu = false,
}: PageHeaderV1Props) {
  const router = useRouter();

  const dispatch = useDispatch();

  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );
  const profile = useSelector((state: any) => state.auth.profile);

  const [showMenuItem, setShowMenuItem] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [secondaryLinks, setSecondaryLinks] = useState(null);

  function renderProfileLink() {
    let profilePic = '/img/icons/guest-user.svg';
    if (isAuthenticated) profilePic = getUserProfilePic(profile);

    return (
      <button
        type="button"
        className={s.profile_pic}
        style={{
          backgroundImage: `url('${profilePic}')`,
          backgroundColor: 'lightgray',
        }}
        onClick={() => {
          if (isAuthenticated) {
            redirectToPWA('/profile');
          } else dispatch(loginPopupUpdate({ show: true }));
        }}
        aria-label="profile"
      >
        {null}
      </button>
    );
  }

  const handleMenuItemClick = () => {
    const el = global.document.getElementsByTagName('body')[0];

    el.style.overflow = showMenuItem ? 'initial' : 'hidden';

    setShowMenuItem(!showMenuItem);
    setActiveStep(1);
    setSecondaryLinks(null);
  };

  const handleMenuLinkClick = (item) => {
    setActiveStep(2);
    setSecondaryLinks(item);
  };

  const handleHashLinkClick = (href: string) => {
    // dispatch(showContactForm({ show: true }));

    const el = global.document.getElementsByTagName('body')[0];
    el.style.overflow = 'initial';
    setShowMenuItem(false);
    setActiveStep(1);
    setSecondaryLinks(null);
    global.location.hash = '';
    global.location.hash = href;
  };

  const handleSublinksClick = (href: string) => {
    const el = global.document.getElementsByTagName('body')[0];
    el.style.overflow = 'initial';
    setShowMenuItem(false);
    setActiveStep(1);
    setSecondaryLinks(null);
    router.push(href);
  };

  return (
    <div className={s.root} style={customStyle}>
      <div className={['cmn_container', s.box].join(' ')}>
        {showBackBtn && (
          <BackButton noPadding onBack={onBackBtnClick}>
            <span className={s.back}>
              <BackIconSVG className={s.back_icon} />
            </span>
          </BackButton>
        )}
        {showLogo && (
          <button className={s.logo} type="button" onClick={onLogoClick}>
            <ParkplusSVG />
          </button>
        )}
        <div className={s.rightSide}>
          {showProfile ? renderProfileLink() : <div className={s.profile_pic} />}
          {showMenu && (
            <div className={s.menu} onClick={() => handleMenuItemClick()}>
              {!showMenuItem ? <MenuSVG /> : <CrossSVG />}
            </div>
          )}
        </div>
        
        {showMenuItem && activeStep === 1 && (
          <div className={s.nav_items}>
            <div className={cn(s.nav_wrapper, s.slide_left)}>
              {renderProfileLink()}
              {Object.keys(HEADER_LINK_LIST).map((item) => {
                const linkItem = HEADER_LINK_LIST[item];
                if (
                  linkItem.type === LINK_TYPE.menu &&
                  isObject(linkItem.options)
                ) {
                  return (
                    <div
                      className={s.nav_link}
                      onClick={() => handleMenuLinkClick(linkItem)}
                      key={linkItem.label}
                    >
                      <p>{linkItem.label}</p>
                      <span className={s.icon}>
                        <ChevronRight
                          height={16}
                          width={16}
                          primaryColor="interaction_button"
                        />
                      </span>
                    </div>
                  );
                }
                if (linkItem.type === LINK_TYPE.rounded) {
                  return (
                    <div
                      className={cn(s.nav_link, s.btn_wrapper)}
                      key={linkItem.href}
                    >
                      <button
                        type="button"
                        className={s.btn}
                        onClick={() => handleHashLinkClick('#contact-us')}
                      >
                        {linkItem.label}
                      </button>
                    </div>
                  );
                }
                if (linkItem.sourceType === SOURCE_TYPE.internal) {
                  return (
                    <div
                      className={s.nav_link}
                      onClick={() => handleSublinksClick(linkItem.href)}
                    >
                      {linkItem.isSpecial && (
                        <span className={s.special}>Hot</span>
                      )}
                      <span>{linkItem.label}</span>
                    </div>
                  );
                }
                return (
                  <div className={s.nav_link}>
                    {linkItem.isSpecial && (
                      <span className={s.special}>Hot</span>
                    )}
                    <a href={getOriginByEnv() + linkItem.href}>
                      {linkItem.label}
                    </a>
                  </div>
                );
              })}
            </div>
            <div className={s.social_wrapper}>
              {SOCIAL_LINKS.map((link) => (
                <a href={link.url} key={link.id} className={s.socials}>
                  {link.icon}
                </a>
              ))}
            </div>
          </div>
        )}
        {showMenuItem && activeStep === 2 && (
          <div className={s.sec_container}>
            <div className={s.sec_top}>
              <div onClick={() => setActiveStep(1)}>
                <svg
                  width="20"
                  height="14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 5.833H4.256l3.977-4.188L6.667 0 0 7l6.667 7 1.566-1.645-3.977-4.188H20V5.833z"
                    fill="#000"
                  />
                </svg>
              </div>
              <div onClick={() => handleMenuItemClick()}>
                <CrossSVG />
              </div>
            </div>

            <div className={cn(s.sec_items, s.slide_left)}>
              {secondaryLinks?.options &&
                Object.keys(secondaryLinks.options).map((key) => {
                  const menuItem = secondaryLinks.options[key];
                  if (menuItem.type === LINK_TYPE.label) {
                    return (
                      <h3 className={s.menu_label} key={menuItem.label}>
                        {menuItem.label}
                      </h3>
                    );
                  }
                  if (menuItem.sourceType === SOURCE_TYPE.hash) {
                    return (
                      <div
                        onClick={() => handleSublinksClick(menuItem.href)}
                        className={s.sec_link}
                        key={menuItem.label}
                      >
                        {menuItem.label}
                      </div>
                    );
                  }
                  if (menuItem.sourceType === SOURCE_TYPE.internal) {
                    return (
                      <div
                        className={s.sec_link}
                        onClick={() => handleSublinksClick(menuItem.href)}
                      >
                        {menuItem.label}
                      </div>
                    );
                  }
                  return (
                    <div>
                      <a
                        href={getOriginByEnv() + menuItem.href}
                        className={s.sec_link}
                      >
                        {menuItem.label}
                      </a>
                    </div>
                  );
                })}
              {secondaryLinks?.options2 &&
                Object.keys(secondaryLinks.options2).map((key) => {
                  const menuItem = secondaryLinks.options2[key];
                  if (menuItem.type === LINK_TYPE.label) {
                    return (
                      <h3 className={s.menu_label} key={menuItem.label}>
                        {menuItem.label}
                      </h3>
                    );
                  }
                  if (menuItem.sourceType === SOURCE_TYPE.hash) {
                    return (
                      <div
                        onClick={() => handleHashLinkClick(menuItem.href)}
                        className={s.sec_link}
                      >
                        {menuItem.label}
                      </div>
                    );
                  }
                  if (menuItem.sourceType === SOURCE_TYPE.internal) {
                    return (
                      <div
                        className={s.sec_link}
                        onClick={() => handleSublinksClick(menuItem.href)}
                      >
                        {menuItem.label}
                      </div>
                    );
                  }
                  return (
                    <div>
                      <a
                        href={getOriginByEnv() + menuItem.href}
                        className={s.sec_link}
                      >
                        {menuItem.label}
                      </a>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PageHeaderV1;
