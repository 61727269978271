/* eslint-disable react/require-default-props */
import React from 'react';
import s from './TitleBar.module.scss';
import { handleBack } from 'core-lib/utils/common';
import { BackButton } from 'ui';

export enum BackBtnType {
  Default,
  White,
  Arrow,
}

type TitleBarProps = {
  showBackBtn?: boolean;
  title: string;
  titleColor?: string;
  titleSize?: string;
  children?: React.ReactNode;
  customChildrenStyle?: any;
  onBack?: () => void;
  customStyle?: any;
  backBtnType?: BackBtnType;
  showTitle?: boolean;
};

export const TitleBar = ({
  showBackBtn = true,
  title,
  titleColor = '#000000',
  titleSize = '14px',
  children,
  onBack,
  customStyle = null,
  customChildrenStyle = null,
  backBtnType = BackBtnType.Default,
  showTitle = true,
}: TitleBarProps) => {
  const onBackClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (onBack) {
      onBack();
    } else {
      handleBack();
    }
  };

  let btnType: 'default' | 'white' | 'arrow' = 'default';
  switch (backBtnType) {
    case BackBtnType.Default:
      btnType = 'default';
      break;
    case BackBtnType.White:
      btnType = 'white';
      break;
    case BackBtnType.Arrow:
      btnType = 'default';
      break;
    default:
      btnType = 'default';
  }

  return (
    <div className={s.bar} style={customStyle}>
      {showTitle && (
        <div className={s.left}>
          {showBackBtn && <BackButton onBack={onBackClick} type={btnType} />}
          <h4
            className={s.title}
            style={{ color: titleColor, fontSize: titleSize }}
          >
            {title}
          </h4>
        </div>
      )}
      <div className={s.children} style={customChildrenStyle}>
        {children}
      </div>
    </div>
  );
};
